import fetchStargazers from "./modules/fetchStargazers";
import animateCounter from "./modules/animateCounter";
import { addCommasToNum } from "./modules/utils";
import tealight from "tealight";
import serializeForm from "form-serialize";
import PerfectScrollbar from "perfect-scrollbar";

function isMobile(agent = navigator.userAgent) {
    return /Android|iPhone|iPad|iPod/i.test(agent);
}

if (!isMobile()) {
    tealight(".layout__two-column__fixed").map(node => {
        new PerfectScrollbar(node);
    });

    tealight("pre > code").map(node => {
        new PerfectScrollbar(node, {
            wheelPropagation: true
        });
    });
}

setTimeout(() => {
    tealight('.load-hidden').map(e => e.classList.remove('load-hidden'));
}, 2500);

tealight("#purchase").map(form => {
    form.addEventListener("focusin", e => {
        e.target.parentNode.parentNode.classList.add("focused");
    });
    form.addEventListener("focusout", e => {
        e.target.parentNode.parentNode.classList.remove("focused");
    });
});

fetchStargazers((stars, fromCache) => {
    tealight(".github__star-count").map(node => {
        if (fromCache) {
            node.innerHTML = addCommasToNum(stars);
        } else {
            animateCounter(node, stars);
        }
    });
});

let nodes = tealight("html, body");
let scrollBuffer = 0;
let isOpen = false;

tealight(".floating-button").map(button => {
    button.addEventListener("click", () => {
        if (!isOpen) {
            scrollBuffer =
                document.documentElement.scrollTop || document.body.scrollTop;
            nodes.forEach(node => {
                node.style.position = "fixed";
                node.style.top = `${scrollBuffer * -1}px`;
            });
            document.body.classList.add("sidebar-open");
            isOpen = true;
        } else {
            nodes.forEach(node => {
                node.style.position = "";
                node.style.top = "";
            });
            document.documentElement.scrollTop = scrollBuffer;
            document.body.scrollTop = scrollBuffer;
            document.body.classList.remove("sidebar-open");
            isOpen = false;
        }
    });
});

// document
//     .querySelector(".floating-button__underlay")
//     .addEventListener("click", () => {
//         nodes.forEach(node => (node.style.position = ""));
//         document.documentElement.scrollTop = scrollBuffer;
//         document.body.classList.remove("sidebar-open");
//     });

/**
 * Google Analytics
 */
window.ga =
    window.ga ||
    function() {
        (ga.q = ga.q || []).push(arguments);
    };
ga.l = +new Date();

ga("create", "UA-55186174-1", "auto");
ga("send", "pageview");

// const sponsor = document.getElementById('sponsor')
// sponsor.addEventListener('click', handleSponsorClick)

// function handleSponsorClick () {
// 	ga('send', 'event', {
// 		eventCategory: 'Sponsors',
// 		eventAction: 'click',
// 		eventLabel: 'e.g. React for Beginners', // optional
// 	})
// }

tealight(".js-async-docsearch").map(script =>
    script.addEventListener(
        "load",
        () => {
            docsearch({
                apiKey: "bc35bddc788fd71169ff91b6a9517ecd",
                indexName: "scrollrevealjs",
                inputSelector: ".search-bar__input",
                debug: true
            });
            docsearch({
                apiKey: "bc35bddc788fd71169ff91b6a9517ecd",
                indexName: "scrollrevealjs",
                inputSelector: ".mobile-nav .search-bar__input",
                debug: true
            });
        },
        false
    )
);

tealight(".js-async-stripe").map(script =>
    script.addEventListener(
        "load",
        () => {
            const stripe = Stripe("pk_live_HHWNHVqg6gjpQOc5xLzAPwIp");
            // const stripe = Stripe("pk_test_O7QKvVW1pmJXLQF0eA7G2Bxo");
            if (window.location.pathname.match(/pricing/) !== null) {
                stripeLoader(stripe);
            }
        },
        false
    )
);

function stripeLoader(stripe) {
    const root = tealight("#stripe-card")[0];
    const fontSize =
        getComputedStyle(document.body)["fontSize"] || isMobile()
            ? "14px"
            : "16px";

    const style = {
        base: {
            color: "#6e7e9d",
            iconColor: "#c7ced9",
            fontSmoothing: "antialiased",
            fontSize,
            fontWeight: "bold",
            "::placeholder": {
                color: "#6e7e9d",
                fontWeight: "normal"
            },
            ":focus": {
                color: "#fefefe",
                fontWeight: "bold",
                "::placeholder": {
                    color: "#afb8c6"
                }
            }
        },
        invalid: {
            color: "#ed5740",
            iconColor: "#ed5740"
        }
    };
    const card = stripe.elements().create("card", { style });

    card.on("focus", () => root.parentNode.classList.add("focused"));
    card.on("blur", () => root.parentNode.classList.remove("focused"));
    card.on("change", event => {
        const output = root.nextSibling;
        if (event.error) {
            output.textContent = event.error.message;
        } else {
            output.textContent = "";
        }
    });

    if (root) card.mount(root);

    tealight("#purchase").map(form => {
        form.addEventListener("submit", event => {
            event.preventDefault();
            stripe.createToken(card).then(result => {
                if (result.error) {
                    const err = form.getElementById("stripe-card").nextSibling;
                    err.textContent = result.error.message;
                } else {
                    tealight(".pricing__submit").map(button =>
                        button.classList.add("is-active")
                    );
                    stripeTokenHandler(result.token);
                }
            });
        });
    });
}

function stripeTokenHandler(token) {
    tealight("#purchase").map(form => {
        const hiddenInput = document.createElement("input");
        hiddenInput.setAttribute("type", "hidden");
        hiddenInput.setAttribute("name", "stripeToken");
        hiddenInput.setAttribute("value", token.id);
        form.appendChild(hiddenInput);
        submitForm(form);
    });
}

function submitForm(form) {
    let xhr = new XMLHttpRequest();
    let uri = "/api/charge";
    let async = true;

    xhr.open("POST", uri, async);
    xhr.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = () => {
        if (xhr.readyState === XMLHttpRequest.DONE) {
            if (xhr.status === 200) {
                handleSuccess(xhr.responseText);
            } else {
                handleError(xhr.responseText);
            }
        }
    };
    xhr.send(serializeForm(form));
}

function handleSuccess(licenseCode) {
    tealight(".pricing__success").map(element => {
        element.dataset.heading = `License Code: ${licenseCode}`;
    });
    tealight(".pricing__matrix").map(element => {
        element.classList.add("is-successful");
    });
    return licenseCode;
}

function handleError(message) {
    tealight(".pricing__submit").map(button => {
        button.classList.remove("is-active");
    });
}
