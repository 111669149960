import { addCommasToNum } from "./utils";
import { easeInOutQuart } from "./easing";
import raf from "miniraf";

export default function animateCounter(node, final) {
    const initial = parseInt(node.innerHTML.replace(",", ""));
    const delta = final - initial;
    const start = Date.now();
    const duration = 4000;

    const loop = () => {
        const elapsed = Date.now() - start;
        const progress = easeInOutQuart(elapsed, initial, delta, duration);

        if (elapsed >= duration) {
            node.innerHTML = addCommasToNum(final);
        } else {
            node.innerHTML = addCommasToNum(progress);
            raf(loop);
        }
    };

    raf(loop);
}
