export function addCommasToNum(num) {
    return parseInt(num)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function centsToDollars(price) {
    const [dollars, cents] = (price / 100).toFixed(2).split(".");
    return `${addCommasToNum(dollars)}.${cents}`;
}
