export default function fetchStargazers(callback = () => {}) {
    const cache = JSON.parse(window.localStorage.getItem("stargazers"));

    if (cache !== null) {
        const elapsed = Date.now() - cache.timestamp;
        if (elapsed < 600000) {
            callback(cache.starcount, true);
            return;
        }
    }

    const xhr = new XMLHttpRequest();
    const uri = "https://scrollrevealjs.org/api/stargazers";
    const async = true;

    xhr.open("GET", uri, async);
    xhr.onreadystatechange = () => {
        if (xhr.readyState === 4) {
            if (xhr.status === 200) {
                const starcount = parseInt(xhr.responseText);
                const data = { starcount, timestamp: Date.now() };
                localStorage.setItem("stargazers", JSON.stringify(data));
                callback(starcount, false);
            }
        }
    };
    xhr.send();
}
